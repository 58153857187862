.root {
  padding: 15px;
  cursor: pointer;
  background-color: #c7a8ef0f;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 0.1px solid #d6d6d6;
  margin: 10px;
  overflow: hidden;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  width: 100%;

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 5px;
    margin: 5px;
    text-align: center;
    border-radius: 30px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  padding-bottom: 10px;

  &:first-child {
    margin-top: 0;
  }
}
